import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of, tap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {PermissionFlagsService} from './permissionFlags.service';
import {AuthState, BasePermission, LoginResponse} from "../types/userAuth.types";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API_URL = `${environment.API_BASE}/auth/login`;
  private readonly PORTAL_URL = environment.PORTAL;
  private readonly AUTH_STORAGE_KEY = 'auth-state';

  private authState = new BehaviorSubject<AuthState>({
    isAuthenticated: false,
    username: '',
    permissions: [],
    accessToken: null,
    refreshToken: null,
  });

  private permissionFlagsService = inject(PermissionFlagsService);
  private http = inject(HttpClient);

  constructor( private router: Router) {
    this.initAuthState();
  }

  signIn(authKey: string): Observable<boolean> {
    const headers = new HttpHeaders().set('auth-key', authKey);

    return this.http.post<LoginResponse>(this.API_URL, {}, { headers }).pipe(
      map(response => {
        return this.handleSignInResponse(response);
      }),
      tap(success => {
        if (success) {
          const url = new URL(window.location.href);
          const params = new URLSearchParams(url.search);
          params.delete('auth-key');

          const queryParams: { [key: string]: string } = {};
          params.forEach((value, key) => {
            queryParams[key] = value;
          });

          this.router.navigateByUrl(
            this.router.createUrlTree(
              [url.pathname],
              { queryParams }
            ),
            { replaceUrl: true }
          );
        }
      }),
      catchError(error => {
        this.handleSignInError(error);
        return of(false);
      })
    );
  }

  signOut(): void {
    this.clearAuthData();
    if (this.PORTAL_URL.startsWith('http')) {
      window.location.href = this.PORTAL_URL;
    } else {
      this.router.navigateByUrl(this.PORTAL_URL);
    }
  }

  getAuthState(): Observable<AuthState> {
    return this.authState.asObservable();
  }

  hasPermission(requiredPath: string, requiredAction: string, status?: string): boolean {
    const currentState = this.authState.value;

    if (!currentState.isAuthenticated) {
      return false;
    }

    if (requiredPath === 'visitors' && status) {
      return this.permissionFlagsService.getPermissionSignal(status)();
    }

    const relevantPermission = currentState.permissions.find(
      perm => perm.resourcePath === requiredPath
    );

    if (!relevantPermission) {
      return false;
    }

    return relevantPermission.context.includes(requiredAction) ||
      relevantPermission.context.includes('all');
  }

  private initAuthState(): void {
    try {
      const savedState = sessionStorage.getItem(this.AUTH_STORAGE_KEY);
      if (savedState) {
        const parsedState = JSON.parse(savedState) as AuthState;
        this.validateAuthState(parsedState);
        this.updateAuthState(parsedState);
      }
    } catch (error) {
      console.error('Error initializing auth state:', error);
      this.clearAuthData();
    }
  }

  private validateAuthState(state: AuthState): void {
    if (!state || !Array.isArray(state.permissions) ||
      state.permissions.length === 0) {
      throw new Error('Invalid auth state structure or empty permissions');
    }
  }

  private handleSignInResponse(response: LoginResponse): boolean {
    if (!response?.data?.accessToken) {
      console.warn('Invalid login response structure');
      return false;
    }

    try {
      const { accessToken, refreshToken, username, userPermissions } = response.data;
      const parsedPermissions = this.parseUserPermissions(userPermissions);

      const newState: AuthState = {
        isAuthenticated: true,
        username,
        permissions: parsedPermissions,
        accessToken,
        refreshToken,
      };

      this.updateAuthState(newState);

      if (parsedPermissions && !parsedPermissions.length) {
        console.warn('No permissions received from server');
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error handling sign-in response:', error);
      return false;
    }
  }

  private handleSignInError(error: HttpErrorResponse): Observable<boolean> {
    console.error('Sign-in error:', error.message);
    this.clearAuthData();
    return of(false);
  }

  private updateAuthState(state: AuthState): void {
    try {
      // Update BehaviorSubject
      this.authState.next(state);

      sessionStorage.setItem(this.AUTH_STORAGE_KEY, JSON.stringify(state));

      if (state.permissions.length > 0) {
        this.permissionFlagsService.updatePermissionFlags(state.permissions);
      }
    } catch (error) {
      console.error('Error updating auth state:', error);
      this.clearAuthData();
    }
  }

  private parseUserPermissions(permissions: string | BasePermission[]): BasePermission[] {
    try {
      let parsedPermissions: BasePermission[];

      if (typeof permissions === 'string') {
        parsedPermissions = JSON.parse(permissions);
      } else if (Array.isArray(permissions)) {
        parsedPermissions = permissions;
      } else {
        console.warn('Invalid permissions format:', permissions);
        return [];
      }

      if (!parsedPermissions?.length) {
        console.warn('Parsed permissions are empty. Original permissions:', permissions);
      }

      return parsedPermissions.map(perm => ({
        ...perm,
        context: this.normalizeContext(perm.context)
      }));
    } catch (error) {
      console.error('Error parsing permissions:', error, 'Original permissions:', permissions);
      return [];
    }
  }

  private normalizeContext(context: string | string[]): string[] {
    if (Array.isArray(context)) {
      return context.map(c => c.trim()).filter(Boolean);
    }
    return context.split(',').map(c => c.trim()).filter(Boolean);
  }

  private clearAuthData(): void {
    const emptyState: AuthState = {
      isAuthenticated: false,
      username: '',
      permissions: [],
      accessToken: null,
      refreshToken: null,
    };

    try {
      this.updateAuthState(emptyState);
      sessionStorage.removeItem(this.AUTH_STORAGE_KEY);
    } catch (error) {
      console.error('Error clearing auth data:', error);
    }
  }
}
